// extracted by mini-css-extract-plugin
export var blogBody = "blog-module--blog-body--8Qqy4";
export var blogBodyHr = "blog-module--blog-body-hr--wX9G6";
export var blogContainer = "blog-module--blog-container--SzBuL";
export var blogContent = "blog-module--blog-content--Ve2EL";
export var blogDate = "blog-module--blog-date--UYvjd";
export var blogGrid = "blog-module--blog-grid--AsuOE";
export var blogHr = "blog-module--blog-hr--AWX4b";
export var blogImage = "blog-module--blog-image--0gvxm";
export var blogImageWrapper = "blog-module--blog-image-wrapper--++bkR";
export var blogPageTitle = "blog-module--blog-page-title--bTGA2";
export var blogTag = "blog-module--blog-tag--OTuwh";
export var blogText = "blog-module--blog-text--o1Bk3";
export var blogTitle = "blog-module--blog-title--bEffC";
export var sideBar = "blog-module--side-bar--doKAM";
export var sideBarBlogsCard = "blog-module--side-bar-blogs-card--0D27H";
export var sideBarBlogsCardBody = "blog-module--side-bar-blogs-card-body--0P0VR";
export var sideBarBlogsCardDate = "blog-module--side-bar-blogs-card-date--LG-Vt";
export var sideBarBlogsCardImage = "blog-module--side-bar-blogs-card-image--PIwMU";
export var sideBarBlogsCardImageWrapper = "blog-module--side-bar-blogs-card-image-wrapper--dFIBP";
export var sideBarBlogsCardTag = "blog-module--side-bar-blogs-card-tag--uyb2v";
export var sideBarBlogsCardTitle = "blog-module--side-bar-blogs-card-title--PF218";
export var sideBarHr = "blog-module--side-bar-hr--WLBJM";
export var sideBarLatestTitle = "blog-module--side-bar-latest-title--73s8J";
export var sideBarLatestTitleJp = "blog-module--side-bar-latest-title-jp--6XCf5";
export var sideBarRelatedTitle = "blog-module--side-bar-related-title--xUhsd";
export var sideBarRelatedTitleJp = "blog-module--side-bar-related-title-jp--H1cVP";