import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Layout from '../components/layout'
import {
  blogContainer,
  blogPageTitle,
  blogText,
  blogHr,
  blogGrid,
  blogBody,
  blogImageWrapper,
  blogImage,
  blogTag,
  blogDate,
  blogTitle,
  blogBodyHr,
  blogContent,
  sideBar,
  sideBarLatestTitle,
  sideBarLatestTitleJp,
  sideBarHr,
  sideBarBlogsCard,
  sideBarBlogsCardImageWrapper,
  sideBarBlogsCardImage,
  sideBarBlogsCardBody,
  sideBarBlogsCardTag,
  sideBarBlogsCardTitle,
  sideBarBlogsCardDate,
  sideBarRelatedTitle,
  sideBarRelatedTitleJp,
} from '../styles/blog.module.css'

const Blog = ({ data }) => {
  const breakpoints = useBreakpoint()

  const blogPost = data.contentfulBlogPost
  const allBlogs = data.allContentfulBlogPost.edges

  return (
    <Layout
      isHome={false}
      title={blogPost.title}
      description={blogPost.description.description}
      image={`https:${blogPost.thumbnail.file.url}`}
      article={true}
    >
      <div className={blogContainer}>
        <h1 className={blogPageTitle}>NEUTRAL Blog</h1>
        <p className={blogText}>
          NEUTRALの最新情報や技術関連、<br/>
          プライベート、「好きを仕事に」している人への<br/>
          インタビュー記事などをお届けします
        </p>
        <hr className={blogHr}/>
        <div className={blogGrid}>
          <div className={blogBody}>
            <div className={blogImageWrapper}>
              <img className={blogImage} alt={blogPost.title} src={blogPost.thumbnail.file.url}/>
              {blogPost.tag && <div className={blogTag}>{`#${blogPost.tag.title}`}</div>}
            </div>
            <p className={blogDate}>{blogPost.publishedDate}</p>
            <h1 className={blogTitle}>{blogPost.title}</h1>
            <hr className={blogBodyHr}/>
            <div className={blogContent}>
              <MDXRenderer>{blogPost.content.childMdx.body}</MDXRenderer>
            </div>
          </div>
          {breakpoints.xs || breakpoints.sm || breakpoints.md
          ? <hr className={blogBodyHr}/>
          : null}
          <div className={sideBar}>
            <h2 className={sideBarLatestTitle}>
              Latest Blog<span className={sideBarLatestTitleJp}>最新記事</span>
            </h2>
            <hr className={sideBarHr}/>
            {allBlogs.map(({ node: post }, index) => (
              index < 3 &&
              <div key={post.id} className={sideBarBlogsCard}>
                <Link to={`/blog/${post.slug}`} className={sideBarBlogsCardImageWrapper}>
                  <img
                    className={sideBarBlogsCardImage}
                    alt={post.title}
                    src={post.thumbnail.file.url}
                  />
                </Link>
                <div className={sideBarBlogsCardBody}>
                  {post.tag && <div className={sideBarBlogsCardTag}>{`#${post.tag.title}`}</div>}
                  <h2 className={sideBarBlogsCardTitle}>{post.title}</h2>
                  <p className={sideBarBlogsCardDate}>{post.publishedDate}</p>
                </div>
              </div>
            ))}
            <h2 className={sideBarRelatedTitle}>
              Related blog<span className={sideBarRelatedTitleJp}>関連記事</span>
            </h2>
            <hr className={sideBarHr}/>
            {allBlogs.map(({ node: post }) => (
              post.tag && blogPost.tag && post.tag.slug === blogPost.tag.slug &&
              <div key={post.id} className={sideBarBlogsCard}>
                <Link to={`/blog/${post.slug}`} className={sideBarBlogsCardImageWrapper}>
                  <img
                    className={sideBarBlogsCardImage}
                    alt={post.title}
                    src={post.thumbnail.file.url}
                  />
                </Link>
                <div className={sideBarBlogsCardBody}>
                  {post.tag && <div className={sideBarBlogsCardTag}>{`#${post.tag.title}`}</div>}
                  <h2 className={sideBarBlogsCardTitle}>{post.title}</h2>
                  <p className={sideBarBlogsCardDate}>{post.publishedDate}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Blog

export const blogQuery = graphql`
  query BlogQuery($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      slug
      publishedDate(formatString: "YYYY.MM.DD")
      description {
        description
      }
      thumbnail {
        file {
          url
        }
      }
      content {
        childMdx {
          body
        }
      }
      tag {
        title
        slug
      }
    }
    allContentfulBlogPost(
      filter: { slug: { ne: $slug } }
      sort: { fields: publishedDate, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          publishedDate(formatString: "YYYY.MM.DD")
          thumbnail {
            file {
              url
            }
          }
          tag {
            title
            slug
          }
        }
      }
    }
  }
`